const useScrollTop = () => {
  const executeScrollTop = () => {
    const mainEl = document.getElementById("scrollableTarget");
    if (!!mainEl) {
      mainEl.scrollTo({ top: 0, behavior: "smooth" });
    }
  };
  return [executeScrollTop];
};

export default useScrollTop;

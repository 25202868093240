import { Experience } from "redux/Experience/types";
import { MUSIC_ITEM_TYPE } from "../../constants/social";
import { SocialProfileLinkTypes, UserProfile } from "redux/User/types";

export type TalentModuleMixItem =
  | ProductItem
  | LinkItem
  | MusicItem
  | OnDemandVideoItem
  | YoutubeVideoItem
  | ExperienceItem
  | PodcastItem
  | FanClubItem
  | BandsintownItem
  | ShopifyProductItem
  | ShopifyModuleItem
  | EventItem
  | DataCaptureFormItem
  | GroupItem;

export type ThirdPartyModuleType =
  | TalentProfileModuleType.SHOP_MY_SHELF
  | TalentProfileModuleType.SHOP_LIST
  | TalentProfileModuleType.BANDSINTOWN
  | TalentProfileModuleType.SEATED
  | TalentProfileModuleType.YOUTUBE_COLLECTION
  | TalentProfileModuleType.PODCAST_AUTOMATION;

// modules profile
export interface CreateTalentProfileModuleRequest<T> {
  order: number;
  type: TalentProfileModuleType;
  items: T;
  expand?: boolean;
  isEdit?: boolean;
  isCreate?: boolean;
}

export interface UpdateTalentProfileModuleRequest<T> {
  id: string;
  order: number;
  type: TalentProfileModuleType;
  items: T[];
  expand?: boolean;
  isEdit?: boolean;
  isCreate?: boolean;
}

export interface ReorderTalentProfileModuleRequest {
  id: string;
  order: number;
}

export interface ReorderShopifyStoreRequest {
  shop: string;
  order: number;
}

export interface autoSaveProfileRequest {
  localizationId: string;
  localizationIds?: string[];
  profile: UserProfile;
  modules: TalentProfileModule<TalentModuleMixItem>[];
  updatedById?: number;
  updateTalentId?: string;
  onNetworkError?: () => void;
  onSuccess?: () => void;
}
export interface publishProfileRequest {
  localizationId: string[];
  publishTalentId?: number;
  onNetworkError?: () => void;
  onSuccess?: () => void;
}
export interface DeleteLocalizationRequest {
  talentProfileId: string;
  localizationId: string;
  onNetworkError?: () => void;
  onSuccess?: () => void;
  onError?: () => void;
}
export interface requestOTPRequest {
  method: "phone" | "email";
  phone?: string;
  email?: string;
  userId?: number;
}
export interface TFAVerificationRequest {
  method: "phone" | "email";
  phone?: string;
  id?: string;
  otp?: string;
}

export enum TalentProfileModuleType {
  LINK = "LINK",
  EXPERIENCE = "EXPERIENCE",
  ON_DEMAND_VIDEO = "ON_DEMAND_VIDEO",
  PRODUCT = "PRODUCT",
  MUSIC = "MUSIC",
  YOUTUBE = "YOUTUBE",
  YOUTUBE_VIDEO = "YOUTUBE_VIDEO",
  YOUTUBE_COLLECTION = "YOUTUBE_COLLECTION",
  PODCAST = "PODCAST",
  PODCAST_AUTOMATION = "PODCAST_AUTOMATION",
  PODCAST_SELECT = "PODCAST_SELECT",
  FAN_CLUB = "FAN_CLUB",
  SHOPIFY = "SHOPIFY",
  BANDSINTOWN = "BANDSINTOWN",
  SHOPIFY_PRODUCT = "SHOPIFY_PRODUCT",
  SHOPIFY_COLLECTION = "SHOPIFY_COLLECTION",
  EVENTS = "EVENTS",
  FORM_DATA = "FORM_DATA",
  GROUP = "GROUP",
  SHOP_MY_SHELF = "SHOP_MY_SHELF",
  SHOP_LIST = "SHOP_LIST",
  SEATED = "SEATED",
}

export const SHOPIFY_STORE_TYPES = [
  TalentProfileModuleType.SHOPIFY,
  TalentProfileModuleType.SHOPIFY_COLLECTION,
  TalentProfileModuleType.SHOPIFY_PRODUCT,
];

export enum DataCaptureFieldType {
  INPUT = "INPUT",
  TEXTAREA = "TEXTAREA",
  SELECT_SINGLE = "SELECT_SINGLE",
  SELECT_MULTIPLE = "SELECT_MULTIPLE",
  LEGALS = "LEGALS",
  PHONE_NUMBER = "PHONE_NUMBER",
  EMAIL_ADDRESS = "EMAIL_ADDRESS",
  SELECT_DATE = "SELECT_DATE",
  SELECT_COUNTRY = "SELECT_COUNTRY",
}

export enum DataCaptureFieldLabel {
  INPUT = "Fill this out",
  TEXTAREA = "Fill this out",
  SELECT_SINGLE = "Make a selection",
  SELECT_MULTIPLE = "Make a selection",
  LEGALS = "Consent & Permissions",
  PHONE_NUMBER = "Enter phone number",
  EMAIL_ADDRESS = "Enter email",
  SELECT_DATE = "Select a date",
  SELECT_COUNTRY = "Select a country",
}

export interface BaseItem {
  order?: number;
  isUpdate?: boolean;
  isCreate?: boolean;
  isSuccess?: boolean;
  error?: any;
  visible?: boolean;
}

export interface LinkItem extends BaseItem {
  url?: string;
  title?: string;
  thumbnail?: string;
  specialOffer?: {
    storeUrl?: string;
    title?: string;
    thumbnail?: string;
    couponCode?: string;
  };
}
export interface BandsintownItem extends BaseItem {
  url?: string;
  metadata?: {
    name: string;
    id: string;
  };
}
export interface SeatedItem extends BaseItem {
  seatedId?: string;
}
export interface ShopMyShelfItem extends BaseItem {
  url?: string;
}
export interface ShopListItem extends BaseItem {
  url?: string;
}
export interface YoutubeItem extends BaseItem {
  url?: string;
  totalSelected?: number;
  sort?: string;
  metadata?: Record<string, any>;
}

export interface ProductItem extends BaseItem {
  url?: string;
  title?: string;
  thumbnail?: string;
  price?: number;
  currency?: string;
}
export interface EventItem extends BaseItem {
  eventDate?: string;
  venueName?: string;
  location?: string;
  ticketLink?: string;
  soldOut?: boolean;
}

export interface ShopifyProductItem extends BaseItem {
  id?: string;
  shop?: string;
  itemIds?: string[];
  collectionId?: string;
  images: { src: string }[];
  title: string;
  variants: ShopifyVariant[];
}

export interface ShopifyVariant {
  price: string;
  priceV2: {
    currencyCode: string;
    amount: string;
  };
}

export interface ShopifyModuleItem extends BaseItem {
  shop?: string;
  itemIds?: string[];
  collectionId?: string;
  order?: number;
}

export interface MusicItemLink extends BaseItem {
  url?: string;
  isVisible?: boolean;
  isTemporary?: boolean;
  artistsToFollow?: MusicArtist[];
  type?: SocialProfileLinkTypes;
}

export enum RELEASE_TYPE {
  KOMI = "KOMI",
  CUSTOM = "CUSTOM",
}
export enum RELEASE_SCOPE {
  LOCAL = "LOCAL",
  GLOBAL = "GLOBAL",
}

export interface MusicArtist {
  name: string;
  url: string;
}
export interface MusicItem extends BaseItem {
  id?: string;
  metadata: Record<string, any>;
  links: MusicItemLink[];
  order?: number;
  urlSlug?: string;
  type?: MUSIC_ITEM_TYPE;
  releaseDate?: string;
  releaseType?: RELEASE_TYPE;
  releaseScope?: RELEASE_SCOPE;
  timezone?: string;
  persistentId: string;
}

export interface PodcastItemLink extends BaseItem {
  url?: string;
  type?: "SPOTIFY" | "STITCHER" | "APPLE_PODCAST" | "OVERCAST" | "AUDIBLE";
}

export interface PodcastItem extends BaseItem {
  id?: string;
  metadata?: Record<string, any>;
  links: PodcastItemLink[];
  numberOfEpisodes?: number;
  activeLatestPodcast?: boolean;
  isDifferent?: boolean;
  customUrl?: string;
}

export interface FanClubItem extends BaseItem {
  id?: string;
  thumbnail?: string;
}

export interface OnDemandVideoItem extends Experience, BaseItem {
  experienceId?: string;
}

export interface YoutubeVideoItem extends BaseItem {
  url?: string;
  metadata?: Record<string, any>;
}

export interface ExperienceItem extends Experience, BaseItem {
  experienceId?: string;
}

export enum DATA_FORM_LAYOUT {
  left = "left",
  right = "right",
}
export interface DataFormFieldItem {
  name: string;
  label: string;
  required: boolean;
  type: DataCaptureFieldType;
  legalType?: LEGALS_TYPE;
  options: OptionItem[];
  text?: string;
  order: number;
}

export enum LEGALS_TYPE {
  DEFAULT_CHECK = "DEFAULT_CHECK",
  DEFAULT_UNCHECK = "DEFAULT_UNCHECK",
  PLAIN_TEXT = "PLAIN_TEXT",
}

export interface OptionItem {
  value?: string;
  label: string;
  checked: boolean;
  order: number;
}

export interface DataFormOptionItem extends OptionItem {
  edit?: boolean;
}
export interface DataCaptureFieldItem
  extends Omit<DataFormFieldItem, "options"> {
  edit?: boolean;
  options?: DataFormOptionItem[];
}

export interface DataCaptureFormItem extends BaseItem {
  layout: DATA_FORM_LAYOUT;
  hasImage: boolean;
  enableImage?: boolean;
  image?: string;
  title: string;
  subTitle?: string;
  form: {
    title?: string;
    subTitle?: string;
    name: string;
    fields: DataFormFieldItem[];
  };
}
export type GroupItem = TalentProfileModule<TalentModuleMixItem>;

export interface TalentProfileModule<T> {
  id?: string;
  name: string;
  type: TalentProfileModuleType | string;
  items: T[];
  order: number;
  expand?: boolean;
  isEdit?: boolean; // check state editing
  isUpdate?: boolean; // check to send dispatch action update
  isCreate?: boolean; // check to send dispatch action create
  isLoading?: boolean;
  talentProfileId?: string;
  localizationId?: string;
  groupId?: string | null;
  showTitle?: boolean;
  visible: boolean;
}
export type TalentProfileModuleGroup<T> = TalentProfileModule<
  TalentProfileModule<T>
>;

export class TalentProfileModuleObj<T> implements TalentProfileModule<T> {
  id?: string;
  name: string;
  type: TalentProfileModuleType | string;
  items: T[];
  order: number;
  expand?: boolean;
  isEdit?: boolean;
  isUpdate?: boolean;
  isCreate?: boolean;
  isLoading?: boolean;
  visible: boolean;

  constructor(profileModule?: TalentProfileModule<T>) {
    this.id = profileModule && profileModule.id ? profileModule.id : "";
    this.name = profileModule && profileModule.name ? profileModule.name : "";
    this.type = profileModule ? profileModule.type : "";
    this.items = profileModule ? profileModule.items : [];
    this.order = profileModule ? profileModule.order : -1;
    this.expand =
      profileModule && profileModule.expand ? profileModule.expand : true;
    this.isEdit =
      profileModule && profileModule.isEdit ? profileModule.isEdit : false;
    this.isUpdate =
      profileModule && profileModule.isUpdate ? profileModule.isUpdate : false;
    this.isCreate =
      profileModule && profileModule.isCreate ? profileModule.isCreate : false;
    this.isLoading =
      profileModule && profileModule.isLoading
        ? profileModule.isLoading
        : false;
    this.visible =
      profileModule && profileModule.visible ? profileModule.visible : true;
  }
}

import { Col, Dropdown, Menu, Row } from "antd";
import { KomiLogo } from "@komi-app/components";
import classNames from "classnames";
import AvatarProfile from "components/AvatarProfile";
import { Icon } from "components/Icon";

import copyToClipboard from "copy-to-clipboard";
import React, { useCallback, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTypedSelector } from "redux/rootReducer";
import { ROLE } from "redux/User/types";
import {
  selectCollaborator,
  selectCollaboratorList,
  selectIsAdminLogin,
  selectTalentProfile,
  selectUserData,
} from "redux/User/selector";
import notification from "utils/notification";
import { userHasRole } from "utils/user";
import { Paragraph, Text } from "../Typography";
import { useAnalytics } from "hooks/useAnalytics";
import "./index.scss";
import { SEGMENT_EVENTS } from "constants/segment";
import { useAutoSave } from "hooks/useAutoSave";

import { MENU_LINKS } from "routes";
import { komiConsumerUrl } from "services/DomainService";
import { RequestAProfileModal } from "./RequestAProfileModal";
import upcoming, { adjust } from "utils/upcoming";
import config from "config";
import { profileUrl } from "utils/url";

const baseUrl = new URL(config.service.url)
function profileLink (username: string = '') {
  return `${baseUrl.protocol}//${username}.${baseUrl.host}/admin`
}

// TODO: refactor properly
function getPages(isUpcoming: boolean = false) {
  return {
    ProfileEditor: {
      name: "Profile Editor",
      path: adjust("", isUpcoming),
      get active() {
        return location.pathname === this.path
          || location.pathname === adjust("/header", isUpcoming)
          || location.pathname === adjust("/theme", isUpcoming)
          || location.pathname === adjust("/social-links", isUpcoming)
          || location.pathname === adjust("/modules", isUpcoming);
      }
    },
    Analytics: {
      name: "Data & Analytics",
      get active () {
        return location.pathname === adjust(MENU_LINKS.ANALYTICS, isUpcoming)
          || location.pathname === adjust(MENU_LINKS.DATA_CAPTURE_FORM, isUpcoming)
      },
      items: [
        {
          name: "Analytics",
          path: adjust(MENU_LINKS.ANALYTICS, isUpcoming)
        },
        {
          name: "Data Export",
          path: adjust(MENU_LINKS.DATA_EXPORT, isUpcoming)
        }
      ]
    },
    Settings: {
      name: "Settings",
      path: isUpcoming
        ? adjust("/settings", isUpcoming)
        : MENU_LINKS.ACCOUNT_SETTINGS,
      get active() {
        return location.pathname.includes(this.path);
      }
    },
    Support: {
      name: "Support"
    }
  }
}

const PreviewLayoutHeader = () => {
  const history = useHistory();
  const { sendSegmentEvent } = useAnalytics();
  const user = useTypedSelector(selectUserData);
  const collaborationTalentProfiles = useTypedSelector(selectCollaboratorList);
  const collaborator = useTypedSelector(selectCollaborator);
  const isAdminLogin = useTypedSelector(selectIsAdminLogin);
  const talentProfileOrigin = useTypedSelector(selectTalentProfile);
  const { onForceSave } = useAutoSave();

  const [requestNewProfileModalIsOpen, setRequestNewProfileModalIsOpen] =
    useState(false);

  const {
    protocol,
    hostname
  } = window.location;

  const protocolTrimmedKomiLink = profileUrl.replace(new RegExp("^http(s)?://"), "");

  const handleMenuClick = (event: any) => {
    if (event.key === MENU_LINKS.ACCOUNT_SETTINGS) {
      onForceSave();
    }
    setTimeout(() => {
      history.push(event.key);
    }, 250);
  };

  const clickCreateProfile = useCallback(() => {
    window.open(`${komiConsumerUrl}/create-profile`);
  }, []);

  const { isUpcoming } = useCallback(() => upcoming(), [])();
  const accountInformationPath = isUpcoming
    ? "/me"
    : "/user/settings";
  const accountInformationUrl = `${config.service.url}${accountInformationPath}`;
  const isAdminLike = userHasRole([user, collaborator], ROLE.Admin)
    || userHasRole([user, collaborator], ROLE.Moderator)
    || userHasRole([user, collaborator], ROLE.External);

  // TODO: refactor

  const Pages = getPages(isUpcoming);
  const menus = {
    analytics: (
      <Menu
        data-testid="header__dropdown-analytics"
        onClick={handleMenuClick}
        className="dropdown-menu__items"
      >
        <Menu.Item key={Pages.Analytics.items[0].path}>
          <Text preset="semibold16">{Pages.Analytics.items[0].name}</Text>
        </Menu.Item>
        <Menu.Item key={Pages.Analytics.items[1].path}>
          <Text preset="semibold16">{Pages.Analytics.items[1].name}</Text>
        </Menu.Item>
      </Menu>
    ),
    supports: (
      <Menu
        data-testid="header__dropdown-support"
        className="dropdown-menu__items"
      >
        <Menu.Item
          key={MENU_LINKS.FAQ}
          onClick={() => sendSegmentEvent(SEGMENT_EVENTS.CLICK_FAQS)}
        >
          <a
            target="_blank"
            href="http://support.komi.io"
            rel="noopener noreferrer"
          >
            <Text preset="semibold16">FAQs</Text>
          </a>
        </Menu.Item>
        <Menu.Item
          key={MENU_LINKS.CONTACT_US}
          onClick={() => sendSegmentEvent(SEGMENT_EVENTS.CLICK_CONTACT_US)}
        >
          <a
            target="_blank"
            href="mailto:support@komi.io"
            rel="noopener noreferrer"
          >
            <Text preset="semibold16">Contact Us</Text>
          </a>
        </Menu.Item>
      </Menu>
    ),
    account: (
      <Menu
        className="dropdown-menu__items account-menu"
        data-testid="header__dropdown-account-menu"
      >
        {!isAdminLike &&
          <>
            <Paragraph
              preset="semibold14"
              className="p__x--20 p__t--16 p__b--8 opacity-6"
            >
              Connected Profiles
            </Paragraph>
            {collaborator ? (
              <>
                {collaborator?.talentProfile && (
                  <Menu.Item>
                    <a href={profileLink(collaborator?.talentProfile.user?.username)}>
                      <Row align="middle">
                        <Col>
                          <AvatarProfile
                            avatarSize={24}
                            avatar={collaborator.talentProfile?.avatar || ""}
                            name={`${collaborator.talentProfile?.firstName || ""} ${collaborator.talentProfile?.lastName || ""
                              }`}
                          />
                        </Col>
                        <Col>
                          <Text preset="semibold16" className="m__l--8 truncate">
                            {`${collaborator.talentProfile?.firstName} ${collaborator.talentProfile?.lastName}`}
                          </Text>
                        </Col>
                      </Row>
                    </a>
                  </Menu.Item>
                )}
                {!isAdminLogin &&
                  !!collaborationTalentProfiles?.length &&
                  collaborationTalentProfiles.map(({ talentProfile }, index) => (
                    <Menu.Item
                      key={`collaborator+${index}`}
                    >
                      <a href={profileLink(talentProfile?.user?.username)}>
                        <Row align="middle">
                          <Col>
                            <AvatarProfile
                              avatarSize={24}
                              avatar={talentProfile.avatar || ""}
                              name={`${talentProfile?.firstName || ""} ${talentProfile?.lastName || ""
                                }`}
                            />
                          </Col>
                          <Col>
                            <Text preset="semibold16" className="m__l--8 truncate">
                              {`${talentProfile.firstName} ${talentProfile.lastName}`}
                            </Text>
                          </Col>
                        </Row>
                      </a>
                    </Menu.Item>
                  ))}
              </>
            ) : (
              <>
                {talentProfileOrigin && user?.username && (
                  <Menu.Item>
                    <a href={profileLink(user?.username)}>
                      <Row align="middle">
                        <Col>
                          <AvatarProfile
                            avatarSize={24}
                            avatar={user?.avatar || ""}
                            name={`${user?.firstName || ""} ${user?.lastName || ""
                              }`}
                          />
                        </Col>
                        <Col>
                            <Text preset="semibold16" className="m__l--8 truncate">
                              {`${user?.firstName} ${user?.lastName}`}
                            </Text>
                        </Col>
                      </Row>
                    </a>
                  </Menu.Item>
                )}
                {!isAdminLogin &&
                  !!user?.collaborationTalentProfiles?.length &&
                  user.collaborationTalentProfiles.map(
                    ({ talentProfile }, index) => (
                      <Menu.Item
                        key={`admin-collaborator+${index}`}
                      >
                        <a href={profileLink(talentProfile?.user?.username)}>
                          <Row align="middle">
                            <Col>
                              <AvatarProfile
                                avatarSize={24}
                                avatar={talentProfile.avatar || ""}
                                name={`${talentProfile?.firstName || ""} ${talentProfile?.lastName || ""
                                  }`}
                              />
                            </Col>
                            <Col>
                              <Text preset="semibold16" className="m__l--8 truncate">
                                {`${talentProfile?.firstName} ${talentProfile?.lastName}`}
                              </Text>
                            </Col>
                          </Row>
                        </a>
                      </Menu.Item>
                    )
                  )}
              </>
            )}
          </>
        }

        {userHasRole([user, collaborator], ROLE.SelfSign) ? (
          <Menu.Item
            key={MENU_LINKS.SETUP_PROFILE}
            onClick={clickCreateProfile}
          >
            <Row align="middle">
              <Col className="d--flex align__items--center">
                <Icon name="add-blue-full-width" width={24} />
              </Col>

              <Col>
                <div className="p__l--8 d--flex flex__direction--column">
                  <Text preset="semibold16">Create a New Profile</Text>
                  <Text preset="semibold14" className="opacity--60">
                    30-day trial available
                  </Text>
                </div>
              </Col>
            </Row>
          </Menu.Item>
        ) : null}

        {userHasRole([user, collaborator], ROLE.Talent) ? (
          <Menu.Item
            key={MENU_LINKS.SETUP_PROFILE}
            onClick={() => setRequestNewProfileModalIsOpen(true)}
          >
            <Row align="middle">
              <Col className="d--flex align__items--center">
                <Icon name="add-blue-full-width" width={24} />
              </Col>

              <Col>
                <div className="p__l--8 d--flex flex__direction--column">
                  <Text preset="semibold16">Request a New Profile</Text>
                </div>
              </Col>
            </Row>
          </Menu.Item>
        ) : null}

        {isAdminLike ? (
          <>
            <Paragraph
              preset="semibold14"
              className="p__x--20 p__t--16 p__b--8 opacity-6"
            >
              Admin
            </Paragraph>
            <Menu.Item
              key="admin"
            >
              <a href={config.admin.url}>
                <Text preset="semibold16">Dashboard</Text>
              </a>
            </Menu.Item>
          </>
        ) : null}
        
        <Paragraph
          preset="semibold14"
          className="p__x--20 p__t--16 p__b--8 opacity-6"
        >
          Account
        </Paragraph>

        <Menu.Item
          key={MENU_LINKS.PROFILE_SETTINGS}
        >
          <a href={accountInformationUrl}>
            <Text preset="semibold16">Account Information</Text>
          </a>
        </Menu.Item>

        <Menu.Item
          key={MENU_LINKS.LOGOUT}
        >
          <Link to={"/logout"}>
            <Text preset="semibold16">Log Out</Text>
          </Link>
        </Menu.Item>
      </Menu>
    ),
  };

  return (
    <>
      <Row
        className="dropdown-menu"
        align="middle"
        justify="space-between"
        wrap={false}
      >
        <Col flex={"1"}>
          <Row>
            <Col>
              <Link to={adjust("", isUpcoming)}>
                <KomiLogo width={100} color="primary-light" />
              </Link>
            </Col>
            {
              user && (
                <>
                  <Col>
                    <div onClick={() => history.push(Pages.ProfileEditor.path)}>
                      <Text
                        preset="semibold16"
                        className={classNames(
                          "dropdown-menu__text cursor-pointer p__x--16 m__l--32",
                          {
                            "actived text--primary": Pages.ProfileEditor.active,
                          }
                        )}
                      >
                        {Pages.ProfileEditor.name}
                      </Text>
                    </div>
                  </Col>
                  <Col className="d--flex align__items--center">
                    <Dropdown overlay={menus.analytics}>
                      <div
                        className={classNames(
                          "d--flex align__items--center p__x--16",
                          {
                            actived: Pages.Analytics.active,
                          }
                        )}
                      >
                        <Text
                          preset="semibold16"
                          className={classNames(
                            "dropdown-menu__text cursor-pointer m__r--8",
                            {
                              "text--primary": Pages.Analytics.active,
                            }
                          )}
                        >
                          {Pages.Analytics.name}
                        </Text>
                        <Icon name="arrow-down-white" />
                      </div>
                    </Dropdown>
                  </Col>
                  <Col>
                    <div onClick={() => history.push(Pages.Settings.path)}>
                      <Text
                        preset="semibold16"
                        className={classNames(
                          "dropdown-menu__text cursor-pointer p__x--16 m__r--8",
                          {
                            "actived text--primary": Pages.Settings.active
                          }
                        )}
                      >
                        {Pages.Settings.name}
                      </Text>
                    </div>
                  </Col>
                  <Col className="d--flex align__items--center">
                    <Dropdown overlay={menus.supports}>
                      <div className="d--flex align__items--center p__x--16">
                        <Text
                          preset="semibold16"
                          className="dropdown-menu__text cursor-pointer m__r--8"
                        >
                          {Pages.Support.name}
                        </Text>
                        <Icon name="arrow-down-white" />
                      </div>
                    </Dropdown>
                  </Col>
                </>
              )
            }
          </Row>
        </Col>
        <Col>
          <Row
            gutter={12}
            align="middle"
            justify={user ? "space-between" : "end"}
          >
            {user && (
              <>
                <Col className="onboarding-profile-link dark-mode">
                  <Row
                    wrap={false}
                    gutter={16}
                    className="komi-link"
                    align="middle"
                  >
                    <Col>
                      <Paragraph
                        className="text--white"
                        preset="semibold16"
                        data-testid="header__link"
                      >
                        {protocolTrimmedKomiLink}
                      </Paragraph>
                    </Col>
                    <Col
                      onClick={() => {
                        copyToClipboard(profileUrl);
                        notification.success({
                          message: `Your profile link has been copied successfully.`,
                        });
                      }}
                      className="height--24 cursor-pointer"
                    >
                      <Row align="middle" justify="center">
                        <Icon
                          width={24}
                          height={24}
                          name="share-link"
                          data-testid="header__copy-link-button"
                        />
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col data-testid="header__dropdown-account-menu-wrapper">
                  <Dropdown overlay={menus.account} placement="bottomRight">
                    <div
                      className="m__l--20"
                      data-testid="header__dropdown-account"
                    >
                      <AvatarProfile
                        avatarSize={44}
                        avatar={user?.talentProfile?.avatar || isAdminLike && user?.avatar || ""}
                        name={
                          user?.talentProfile
                            ? `${user?.talentProfile?.firstName || user?.firstName
                            } ${user?.talentProfile?.lastName || user?.lastName
                            }`
                            : "Admin Komi"
                        }
                      />
                    </div>
                  </Dropdown>
                </Col>
              </>
            )}
          </Row>
        </Col>
      </Row>

      <RequestAProfileModal
        visible={requestNewProfileModalIsOpen}
        onCancel={() => setRequestNewProfileModalIsOpen(false)}
        onOk={() => setRequestNewProfileModalIsOpen(false)}
      />
    </>
  );
};

export default PreviewLayoutHeader;

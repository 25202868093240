import "antd/dist/antd.less";
import { AutoSaveProvider } from "components/AutoSaveProvider";
import ModulesProvider from "components/ModulesProvider";
import NotSupportSizeBrowser from "components/NotSupportSizeBrowser";
import { ExchangeRateDefault } from "constants/exchange-rate";
import "fonts/SFProDisplay/stylesheet.scss";
import { useNotificationRequest, useWindowSize } from "hooks";
import { useQuery } from "hooks/useQuery";
import Cookies from "js-cookie";
import LogRocket from "logrocket";
import { getCorrelationId } from "@komi-app/correlation";
import moment from "moment";
import { updateTalentProfileActions } from "pages/BecomeATalent/Reducer/action";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useTypedSelector } from "redux/rootReducer";
import {
  detectLocalCurrencyActions,
  getExchangeRateActions,
  getExchangeRateUSDActions,
  getUserProfileActions,
  logoutActions,
} from "redux/User/actions";
import {
  selectIsAdminLogin,
  selectIsCheckLocalCurrency,
  selectUserData,
} from "redux/User/selector";
import Routes from "routes";

import {
  KOMI_SPOTIFY_ACCESS_TOKEN,
  KOMI_SPOTIFY_REFRESH_TOKEN,
} from "services/SpotifyService";
import { KOMI_TALENT_CURRENCY } from "services/UserService";
import { IframeMessage } from "utils/iframeMessage";
import "video.js/dist/video-js.css";
import "./App.scss";
import "./i18n";
import { getBookingSetupActions } from "./redux/Booking/actions";
import { webviewMessage } from "./utils/webviewMessage";
import "@komi-app/components/dist/index.css";
import { UserAgentProvider } from "context/user-agent";
import config from "config";

LogRocket.init(config.logRocket.id, {
  rootHostname: "komi.io",
  console: {
    isEnabled: false,
  },
  dom: {
    privateAttributeBlocklist: ["data-no-track"],
  },
});
const KOMI_FIRST_SETUP_ONBOARDING = "KOMI_FIRST_SETUP_ONBOARDING";

const App = () => {
  const detectCurrency = useTypedSelector(selectIsCheckLocalCurrency);
  const isAdminLogin = useTypedSelector(selectIsAdminLogin);
  const user = useTypedSelector(selectUserData);
  const location = useLocation();
  const query = useQuery();
  //TODO: replace the below accessTokens
  const accessToken = query?.get("access_token"); // redirect from admin
  const token = query?.get("token"); // redirect from consumer
  const talentProfileId = query?.get("talentProfileId"); // redirect from consumer
  const router = useHistory();
  const [isNotSetup, setIsNotSetup] = useState(false);
  const [firstRoute, setFirstRoute] = useState<any>();
  const { width } = useWindowSize();
  const isNotSupport = width && width < 1040;

  useEffect(() => {
    setFirstRoute(router?.location?.pathname);
    if (
      firstRoute !== "/collaborator-login" || !isNotSupport
        ? !user
        : !talentProfileId
    ) {
      return;
    }
    const checkSetupStatus = () => {
      if (!isNotSupport && user) {
        const { talentProfile } = user;

        const isOnboarding =
          talentProfile?.onboardSteps &&
          Object.keys(talentProfile?.onboardSteps).every(
            (key: string) => !(talentProfile?.onboardSteps as any)[key]
          );
        if (isOnboarding && talentProfile) {
          const result = localStorage.getItem(KOMI_FIRST_SETUP_ONBOARDING);
          const data = result ? JSON.parse(result) : {};
          if (!data.items?.includes(talentProfile?.id)) {
            localStorage.setItem(
              KOMI_FIRST_SETUP_ONBOARDING,
              JSON.stringify({
                items: [...(data.items || []), talentProfile?.id],
              })
            );
            setIsNotSetup(true);
            return;
          }
        }
        return;
      }
      if (talentProfileId) {
        const result = localStorage.getItem(KOMI_FIRST_SETUP_ONBOARDING);
        const data = result ? JSON.parse(result) : {};
        if (!data.items?.includes(talentProfileId)) {
          localStorage.setItem(
            KOMI_FIRST_SETUP_ONBOARDING,
            JSON.stringify({
              items: [...(data.items || []), talentProfileId],
            })
          );
          setIsNotSetup(true);
          return;
        }
      }
    };
    checkSetupStatus();
  }, [user, firstRoute, isNotSupport]);

  useNotificationRequest();
  const dispatch = useDispatch();
  moment.updateLocale("en-gb", {
    weekdaysMin: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  });

  // fix bug spotify token expires
  useEffect(() => {
    if (Cookies.get(KOMI_SPOTIFY_ACCESS_TOKEN)) {
      Cookies.remove(KOMI_SPOTIFY_ACCESS_TOKEN);
    }
    if (Cookies.get(KOMI_SPOTIFY_REFRESH_TOKEN)) {
      Cookies.remove(KOMI_SPOTIFY_REFRESH_TOKEN);
    }
  }, []);

  useEffect(() => {
    // prevents using talent.*
    //  or local.komi.ci
    const { location } = window;
    if (
      location.hostname.startsWith('talent.')
      ||
      (
        location.origin === config.service.url
          && location.pathname === '/'
      )          
    ) {
      window.location.href = config.client.url;
    }
  }, []);

  useEffect(() => {
    if (user) {
      (window as any)?.analytics?.reset();
      (window as any)?.analytics?.identify(user.id, {
        Id: user.id,
        Name: `${user.talentProfile?.firstName} ${user.talentProfile?.lastName}`,
        Email: user.email,
        Platform: webviewMessage.isWebView()
          ? "Webview"
          : isMobile
          ? "Responsive"
          : "Web",
      });
    }
  }, [user]);

  useEffect(() => {
    const correlationId =
      sessionStorage.getItem("correlationId") || getCorrelationId();
    sessionStorage.setItem("correlationId", correlationId!);

    LogRocket.identify(
      correlationId!,
      user
        ? {
            name: `${user.talentProfile?.firstName} ${user.talentProfile?.lastName}`,
            email: user?.email || "",
          }
        : undefined
    );
  }, [user]);

  useEffect(() => {
    dispatch(getUserProfileActions.REQUEST());
  }, [router, dispatch, accessToken, token]);

  useEffect(() => {
    if (detectCurrency) return;
    if (user && user.localCurrency) {
      Cookies.set(KOMI_TALENT_CURRENCY, user.localCurrency, {
        expires: 999999,
      });
      dispatch(getExchangeRateActions.REQUEST(user.localCurrency));
      dispatch(getExchangeRateUSDActions.REQUEST());
      dispatch(detectLocalCurrencyActions.SUCCESS(true));
    } else if (user && !user.localCurrency && !isAdminLogin) {
      // we will not detect local with expert
      if (IframeMessage.inIframe()) return;
      (async () => {
        const currency = ExchangeRateDefault.localCurrency;
        Cookies.set(KOMI_TALENT_CURRENCY, currency, { expires: 999999 });
        dispatch(
          updateTalentProfileActions.REQUEST({ localCurrency: currency })
        );
        dispatch(getExchangeRateActions.REQUEST(currency));
        dispatch(getExchangeRateUSDActions.REQUEST());
      })();
    }

    return () => {};
  }, [dispatch, user, detectCurrency]);

  // for Admin
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const localCurrency: any = query?.get("localCurrency");
    if (!user || !localCurrency || !IframeMessage.inIframe()) return;
    Cookies.set(KOMI_TALENT_CURRENCY, localCurrency, { expires: 999999 });
    dispatch(getExchangeRateActions.REQUEST(localCurrency));
    dispatch(getExchangeRateUSDActions.REQUEST());
  }, [user]);

  return (
    <React.Fragment>
      {isNotSupport ? (
        <NotSupportSizeBrowser isNotSetup={isNotSetup} />
      ) : (
        <UserAgentProvider>
          <AutoSaveProvider>
            <ModulesProvider>
              <Routes />
            </ModulesProvider>
          </AutoSaveProvider>
        </UserAgentProvider>
      )}
    </React.Fragment>
  );
};

export default App;

import Layout from "antd/lib/layout";
import { Content, Header } from "antd/lib/layout/layout";
import classnames from "classnames";
import Loading from "components/Loading";
import PreviewLayoutHeader from "components/PreviewLayoutHeader";
import SubscriptionBanner from "components/SubscriptionBanner";
import { MINUTES } from "constants/date";
import { SSXSubscriptionStage } from "constants/trial";
import { subscriptionTrialForUser } from "utils/subscription";
import {
  selectProfileBuilderSubscription,
  selectPaymentMethod,
} from "redux/User/selector";

import { useWindowSize } from "hooks/useWindowSize";
import moment from "moment";
import React, {
  ReactNode,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useTypedSelector } from "redux/rootReducer";
import {
  selectCollaborator,
  selectIsCollaborator,
  selectTalentProfile,
  selectUserData,
} from "redux/User/selector";
import { firebaseService } from "services";
import "./PreviewLayout.scss";
import { useHistory } from "react-router-dom";

interface IProps {
  children?: ReactNode;
  className?: string;
}

const PreviewLayout: React.FC<IProps> = ({ children, className = "" }) => {
  const { height } = useWindowSize();
  const history = useHistory();
  const talentProfile = useTypedSelector(selectTalentProfile);
  const isCollaborator = useTypedSelector(selectIsCollaborator);
  const collaborator = useTypedSelector(selectCollaborator);
  const user = useTypedSelector(selectUserData);
  const subscription = subscriptionTrialForUser(
    useTypedSelector(selectProfileBuilderSubscription)!
  );

  const attributeKey = useMemo(
    () => (talentProfile?.id ? `talent/${talentProfile?.id}` : null),
    [talentProfile?.id]
  );

  // Refresh the page after 15 minutes
  useEffect(() => {
    localStorage.setItem(`EXPIRED_TIME`, `${Date.now() + MINUTES["15"]}`);

    const interval = setInterval(() => {
      const expiredTime = localStorage.getItem(`EXPIRED_TIME`);
      if (expiredTime && parseInt(expiredTime, 10) < Date.now()) {
        window.location.reload();
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  // Write last connection times to Firebase
  const updateAttributesData = useCallback(
    async (data = {}) => {
      if (attributeKey) {
        try {
          const currentAttrs =
            (await firebaseService.getOnce(attributeKey)).val() || {};

          let newAttrs = {
            ...currentAttrs,
            ...data,
            [`${collaborator?.id ?? user?.id}`]: {
              lastConnection: moment().toISOString(),
            },
          };
          if (currentAttrs?.startedAt) {
            newAttrs = {
              ...currentAttrs,
              talentLastConnection: moment().toISOString(),
            };
          }
          await firebaseService.write(attributeKey, newAttrs);
        } catch (error) {
          console.error("error: ", error);
        }
      }
    },
    [attributeKey, collaborator?.id, user?.id, isCollaborator]
  );

  // Perform the above attributes writing every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      updateAttributesData();
    }, 5000);
    return () => clearInterval(interval);
  }, [updateAttributesData]);

  const layoutClass = classnames({
    "preview-layout": true,
    [className]: true,
  });

  const onInvalidPage = () =>
    history.location.pathname.includes("/settings") || 
    history.location.pathname.includes("/analytics") || 
    history.location.pathname.includes("/data-export");

  const pageContentClass = classnames({
    "preview-layout__page-content": true,
    "preview-layout__page-content--trial-banner":
      subscription.stage !== SSXSubscriptionStage.None && !onInvalidPage(),
  });

  return (
    <>
      <Layout
        className={layoutClass}
        style={{
          minHeight: `${height}px`,
        }}
      >
        <Header className="preview-layout__header fluid-container">
          <PreviewLayoutHeader />
        </Header>
        <Content>
          <Layout className="preview-layout__content">
            <div className="fluid-container d--flex flex--1 flex__direction--column">
              {!onInvalidPage() && <SubscriptionBanner {...subscription} />}
              <Content className={pageContentClass}>
                <Suspense fallback={<Loading />}>{children}</Suspense>
              </Content>
            </div>
          </Layout>
        </Content>
      </Layout>
    </>
  );
};

export default PreviewLayout;

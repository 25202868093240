import EmptyLayout from "layout/EmptyLayout";
import ExperienceLayout from "layout/ExperienceLayout";
import ExperienceContent from "pages/ExperienceContent";
import MainLayout from "layout/MainLayout";
import React, { ComponentType, lazy, ReactNode, Suspense } from "react";
import {
  Redirect,
  Route,
  RouteProps,
  Switch,
  useRouteMatch,
} from "react-router-dom";
import { useQuery } from "hooks/useQuery";
import { useTypedSelector } from "redux/rootReducer";
import {
  selectUserData,
} from "redux/User/selector";
import ExpertTerms from "../pages/ExpertTerms";
import Loading from "components/Loading";
const VideoCall = lazy(() => import("pages/VideoCall"));
const Meeting = lazy(() => import("pages/Meeting"));
const LiveStream = lazy(() => import("pages/LiveStream"));
const Profile = lazy(() => import("pages/Profile"));
const Analytics = lazy(() => import("pages/Analytics"));
const DataExport = lazy(() => import("pages/DataExport"));
const CreateExperienceContent = lazy(
  () => import("pages/CreateExperienceContent")
);
const CreateExperience1To1 = lazy(() => import("pages/CreateExperience1To1"));
const CreateECommerce = lazy(() => import("pages/CreateECommerce"));
const CreateExperienceLiveClass = lazy(
  () => import("pages/CreateExperienceLiveClass")
);
const CreateExperienceInterActiveLiveClass = lazy(
  () => import("pages/CreateExperienceInterActiveLiveClass")
);
const CreateExclusiveContent = lazy(
  () => import("pages/CreateExclusiveContent")
);
const CreateExperienceCourse = lazy(
  () => import("pages/CreateExperienceCourse")
);
const AccountSettingContainer = lazy(
  () => import("pages/AccountSettingContainer")
);
const AccountSettingSubscription = lazy(
  () => import("pages/AccountSetting/Subscription")
);

const UserSettingContainer = lazy(() => import("pages/UserSetting"));

const CreateBundle = lazy(() => import("pages/CreateExperienceBundle"));

const Home = lazy(() => import("pages/Home"));
import LogoutPage from "pages/Logout";
import PreviewLayout from "layout/PreviewLayout";
const ErrorPage = lazy(() => import("pages/ErrorPage"));
const UnauthorizedPage = lazy(() => import("pages/UnauthorizedPage"));
const NotfoundPage = lazy(() => import("pages/NotFoundPage"));
const BecomeATalent = lazy(() => import("pages/BecomeATalent"));
const SetupPayout = lazy(() => import("pages/SetupPayout"));
const AvailabilityPage = lazy(() => import("pages/AvailabilityPage"));
const AuthCronofy = lazy(() => import("pages/AuthCronofy"));
const AuthShopify = lazy(() => import("pages/AuthShopify"));
const InteractiveLiveClass = lazy(() => import("pages/InteractiveLiveClass"));
const TeamMembers = lazy(
  () => import("../components/ProfileSettings/TeamMembers/TeamMembers")
);

const SwitchToTalent = lazy(() => import("pages/SwitchToTalent"));
const BecomeATalentRedirect = lazy(() => import("pages/BecomeATalentRedirect"));

interface ILayoutProps {
  children: ReactNode;
  showHeader?: boolean;
  showSidebar?: boolean;
  isExperienceFlow?: boolean;
  isLivestreamFLow?: boolean;
  className?: string;
}

interface IProps extends RouteProps {
  loading?: boolean;
  component: ComponentType<RouteProps>;
  layout: ComponentType<ILayoutProps>;
  showSidebar?: boolean;
  isExperienceFlow?: boolean;
  isLivestreamFLow?: boolean;
  className?: string;
}

export function upcoming (path: string) {
  return `/admin${path}`;
}

export enum MENU_LINKS {
  ANALYTICS = "/analytics",
  DATA_CAPTURE_FORM = "/data-capture-form", //Legacy
  DATA_EXPORT= "/data-export",
  ACCOUNT_SETTINGS = "/account-setting",
  PROFILE_SETTINGS = "/user/settings",
  TEAM_MEMBERS = "/team-member",
  FAQ = "/faq",
  CONTACT_US = "/contact",
  LOGOUT = "/logout",
  SETUP_PROFILE = "/setup-profile",
}

export const ProtectedRoute = ({
  component: Component,
  layout: Layout,
  showSidebar,
  isExperienceFlow,
  isLivestreamFLow,
  className,
  ...rest
}: IProps) => {
  const query = useQuery();
  const embedded = query.get("embedded");
  const user = useTypedSelector(selectUserData);
  const showHeader = !!embedded ?false : true

  return (
    <Route
      {...rest}
      render={(props) => (
          <Layout
            showHeader={showHeader}
            showSidebar={showSidebar}
            isExperienceFlow={isExperienceFlow}
            isLivestreamFLow={isLivestreamFLow}
            className={className}
          >
            {
              user
                ? <Component {...props} />
                : <Loading />
            }
          </Layout>
        )
        //TODO: redirect to consumer if there is no access token 
        // <Redirect to="/redirect-consumer" />
      }
    />
  );
};

export const PublicRoute = ({
  component: Component,
  layout: Layout,
  showSidebar,
  isExperienceFlow,
  className,
  ...rest
}: IProps) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout
        showSidebar={showSidebar}
        isExperienceFlow={isExperienceFlow}
        className={className}
      >
        <Component {...props} />
      </Layout>
    )}
  />
);

const ExperienceRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <ProtectedRoute
        showSidebar
        exact
        path={path}
        layout={MainLayout}
        component={() => <Redirect to="/purchases" />}
      />
      {/* <ProtectedRoute
        path={`${path}/purchases/:id`}
        layout={MainLayout}
        component={ExperiencePurchaseDetail}
      />
      <ProtectedRoute
        showSidebar
        path={`${path}/purchases`}
        layout={MainLayout}
        component={ExperiencePurchaseList}
      />
      <ProtectedRoute
        path={`${path}/scheduled`}
        component={ExperienceScheduled}
        layout={MainLayout}
        showSidebar
      /> */}
      <ProtectedRoute
        isExperienceFlow
        path={`${path}/content/create/one_to_one`}
        layout={ExperienceLayout}
        component={CreateExperience1To1}
      />
      <ProtectedRoute
        isExperienceFlow
        path={`${path}/content/create/live_class`}
        layout={ExperienceLayout}
        component={CreateExperienceLiveClass}
      />
      <ProtectedRoute
        isExperienceFlow
        path={`${path}/content/create/interactive_live_class`}
        layout={ExperienceLayout}
        component={CreateExperienceInterActiveLiveClass}
      />
      <ProtectedRoute
        isExperienceFlow
        path={`${path}/content/create/course`}
        layout={ExperienceLayout}
        component={CreateExperienceCourse}
      />
      <ProtectedRoute
        isExperienceFlow
        path={`${path}/content/create/exclusive_content`}
        layout={ExperienceLayout}
        component={CreateExclusiveContent}
      />
      <ProtectedRoute
        isExperienceFlow
        path={`${path}/content/create/e_commerce`}
        layout={ExperienceLayout}
        component={CreateECommerce}
      />
      <ProtectedRoute
        isExperienceFlow
        path={`${path}/content/create/bundle`}
        layout={ExperienceLayout}
        component={CreateBundle}
      />
      <ProtectedRoute
        isExperienceFlow
        path={`${path}/content/create`}
        layout={ExperienceLayout}
        component={CreateExperienceContent}
      />
      <ProtectedRoute
        isExperienceFlow
        path={`${path}/content/one_to_one/:id`}
        layout={ExperienceLayout}
        component={CreateExperience1To1}
      />
      <ProtectedRoute
        isExperienceFlow
        path={`${path}/content/live_class/:id`}
        layout={ExperienceLayout}
        component={CreateExperienceLiveClass}
      />
      <ProtectedRoute
        isExperienceFlow
        path={`${path}/content/interactive_live_class/:id`}
        layout={ExperienceLayout}
        component={CreateExperienceInterActiveLiveClass}
      />
      <ProtectedRoute
        isExperienceFlow
        path={`${path}/content/exclusive_content/:id`}
        layout={ExperienceLayout}
        component={CreateExclusiveContent}
      />
      <ProtectedRoute
        isExperienceFlow
        path={`${path}/content/course/:id`}
        layout={ExperienceLayout}
        component={CreateExperienceCourse}
      />
      <ProtectedRoute
        isExperienceFlow
        path={`${path}/content/e_commerce/:id`}
        layout={ExperienceLayout}
        component={CreateECommerce}
      />
      <ProtectedRoute
        isExperienceFlow
        path={`${path}/content/bundle/:id`}
        layout={ExperienceLayout}
        component={CreateBundle}
      />
      <ProtectedRoute
        showSidebar
        path={`${path}/content`}
        layout={MainLayout}
        component={ExperienceContent}
      />
    </Switch>
  );
};

const VideoCallRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <ProtectedRoute
        exact
        path={`${path}/:id`}
        layout={({ children }) => (
          <Suspense fallback={null}>{children}</Suspense>
        )}
        component={() => <VideoCall />}
      />
    </Switch>
  );
};

const Routes = () => {
  return (
    <Switch>
      <ProtectedRoute
        exact
        path={[
          "/",
          "/header",
          "/theme",
          "/social-links",
          "/modules",
        ]}
        layout={PreviewLayout}
        showSidebar
        component={Profile}
      />

      <ProtectedRoute
        exact
        path="/home"
        layout={MainLayout}
        showSidebar
        component={Home}
      />
      <ProtectedRoute
        exact
        path="/logout"
        layout={MainLayout}
        showSidebar
        component={LogoutPage}
      />
      <ProtectedRoute
        exact
        path="/profile"
        layout={MainLayout}
        showSidebar
        component={Profile}
        className="main-layout--profile"
      />
      <ProtectedRoute
        exact
        path="/team-member"
        layout={PreviewLayout}
        showSidebar
        component={TeamMembers}
        className="preview-layout--additional-page preview-layout--additional-page-team-member"
      />
      <ProtectedRoute
        showSidebar
        exact
        path="/account-setting"
        layout={MainLayout}
        component={() => <Redirect to="/account-setting/profile-information" />}
      />
      {/* TODO: Subscription routes here */}
      <ProtectedRoute
        exact
        path="/account-setting/subscription/:step"
        layout={MainLayout}
        component={AccountSettingSubscription}
        className="preview-layout--additional-page preview-layout--additional-page-account-setting"
      />
      <ProtectedRoute
        exact
        path="/account-setting/:section"
        layout={PreviewLayout}
        showSidebar
        component={AccountSettingContainer}
        className="preview-layout--additional-page preview-layout--additional-page-account-setting"
      />

      <ProtectedRoute
        exact
        path="/user/settings"
        layout={MainLayout}
        showSidebar
        component={UserSettingContainer}
        className="preview-layout--additional-page preview-layout--additional-page-account-setting"
      />

      <ProtectedRoute
        exact
        path="/analytics"
        layout={PreviewLayout}
        showSidebar
        component={Analytics}
        className="preview-layout--additional-page"
      />

      {/* <ProtectedRoute
        exact
        path="/fan-club"
        layout={PreviewLayout}
        showSidebar
        component={FanClub}
        className="preview-layout--additional-page preview-layout--additional-page-fan-club"
      /> */}

      {/**
       * Route for Data export which appears under the 'Data & Analytics' tab
       * Contains 2 views: Data Capture Forms (prev it's own page) + Pre Release Export
       **/}
      <ProtectedRoute
        exact
        path="/data-export"
        layout={PreviewLayout}
        showSidebar
        component={DataExport}
        className="preview-layout--additional-page preview-layout--additional-page-fan-club"
      />

      <PublicRoute
        path="/switch-to-talent"
        showSidebar
        layout={PreviewLayout}
        component={SwitchToTalent}
      />

      <PublicRoute
        path="/expert-terms"
        layout={EmptyLayout}
        component={ExpertTerms}
      />
      <PublicRoute
        path="/redirect-become-a-talent"
        layout={EmptyLayout}
        component={BecomeATalentRedirect}
      />
      <PublicRoute
        path="/become-a-talent"
        layout={EmptyLayout}
        component={BecomeATalent}
      />
      <PublicRoute
        path="/setup-payout"
        layout={EmptyLayout}
        component={SetupPayout}
      />
      <Route path="/experience" component={ExperienceRoutes} />
      <Route path="/video-call" component={VideoCallRoutes} />
      <ProtectedRoute
        path="/waiting-room/:id"
        component={LiveStream}
        layout={ExperienceLayout}
        isLivestreamFLow
        className="main-layout--livestream"
      />
      <ProtectedRoute
        path="/livestream/:id"
        component={Meeting}
        layout={ExperienceLayout}
        isLivestreamFLow
        className="main-layout--livestream"
      />
      <ProtectedRoute
        path="/interactive-live-class/:id"
        component={InteractiveLiveClass}
        layout={({ children }) => (
          <Suspense fallback={null}>{children}</Suspense>
        )}
      />
      <ProtectedRoute
        path="/availability"
        component={AvailabilityPage}
        layout={MainLayout}
        showSidebar
      />
      <ProtectedRoute
        path="/experiences"
        component={ExperienceContent}
        layout={MainLayout}
        showSidebar
      />
      <ProtectedRoute
        path="/auth/cronofy"
        component={AuthCronofy}
        layout={EmptyLayout}
      />
      <ProtectedRoute
        path="/auth/shopify"
        component={AuthShopify}
        layout={EmptyLayout}
      />
      <PublicRoute path="/errors" component={ErrorPage} layout={EmptyLayout} />
      <PublicRoute
        path="/401"
        component={UnauthorizedPage}
        layout={EmptyLayout}
      />

      {/* Upcoming paths */}
      <ProtectedRoute
          exact
          path={[
            "/admin",
            "/admin/header",
            "/admin/theme",
            "/admin/social-links",
            "/admin/modules",
          ]}
          layout={PreviewLayout}
          showSidebar
          component={Profile}
        />
      {/* <ProtectedRoute
          exact
          path={upcoming("/profile")}
          layout={MainLayout}
          showSidebar
          component={Profile}
          className="main-layout--profile"
        /> */}
      <ProtectedRoute
        exact
        path={upcoming("/team-member")}
        layout={PreviewLayout}
        showSidebar
        component={TeamMembers}
        className="preview-layout--additional-page preview-layout--additional-page-team-member"
      />
      <ProtectedRoute
        showSidebar
        exact
        path={upcoming("/settings")}
        layout={MainLayout}
        component={() => <Redirect to={upcoming("/settings/profile-information")} />}
      />
      <ProtectedRoute
        exact
        path={upcoming("/settings/subscription/:step")}
        layout={MainLayout}
        component={AccountSettingSubscription}
        className="preview-layout--additional-page preview-layout--additional-page-account-setting"
      />
      <ProtectedRoute
        exact
        path={upcoming("/settings/:section")}
        layout={PreviewLayout}
        showSidebar
        component={AccountSettingContainer}
        className="preview-layout--additional-page preview-layout--additional-page-account-setting"
      />
      <ProtectedRoute
        exact
        path="/me"
        layout={MainLayout}
        showSidebar
        component={UserSettingContainer}
        className="preview-layout--additional-page preview-layout--additional-page-account-setting"
      />
      <ProtectedRoute
        exact
        path={upcoming("/analytics")}
        layout={PreviewLayout}
        showSidebar
        component={Analytics}
        className="preview-layout--additional-page"
      />
      <ProtectedRoute
        exact
        path={upcoming("/data-export")}
        layout={PreviewLayout}
        showSidebar
        component={DataExport}
        className="preview-layout--additional-page preview-layout--additional-page-fan-club"
      />
      <PublicRoute
        path={upcoming("/redirect-become-a-talent")}
        layout={EmptyLayout}
        component={BecomeATalentRedirect}
      />
      <ProtectedRoute
        path={upcoming("/auth/shopify")}
        component={AuthShopify}
        layout={EmptyLayout}
      />

      <PublicRoute path="*" component={NotfoundPage} layout={EmptyLayout} />
    </Switch>
  );
};

export default React.memo(Routes);

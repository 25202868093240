import React from "react";
import classNames from "classnames";
import { useHistory } from "react-router";
import "./SubscriptionBanner.scss";

import { SSXSubscriptionStage } from "constants/trial";
import { getSubscriptionOverviewData, SubscriptionDetails } from "utils/subscription";

import { ReactComponent as Time } from "icons/ssx/ssx-time.svg";
import { ReactComponent as Warning } from "icons/ssx/ssx-warning.svg";
import { ReactComponent as Error } from "icons/ssx/ssx-error.svg";

import { SEGMENT_EVENTS } from "constants/segment";
import { useUserAgentContext } from "context/user-agent";
import { useAnalytics } from "hooks/useAnalytics";
import { OverviewStatus } from "types/sales";
import { useTypedSelector } from "redux/rootReducer";
import { selectCollaborator, selectProfileBuilderSubscription, selectUserData } from "redux/User/selector";
import notification from "utils/notification";
import { hasOwnership } from "utils/user";

const Icon = ({ stage }: SubscriptionDetails) => {
  const classes = classNames({ "ssx-subscription-banner__info--icon": true });

  switch (stage) {
    case SSXSubscriptionStage.Early:
      return <Time className={classes} />;

    case SSXSubscriptionStage.Late:
      return <Warning className={classes} />;

    case SSXSubscriptionStage.Expired:
    case SSXSubscriptionStage.Inactive:
      return <Error className={classes} />;

    default:
      return null;
  }
};

const Message = (props: SubscriptionDetails) => {
  const {
    stage,
    trialRemainingDaysFormatted,
    subscriptionTrialEndAtFormatted,
  } = props;
  const classes = classNames({
    "ssx-subscription-banner__info--message": true,
  });

  switch (stage) {
    case SSXSubscriptionStage.Early:
      return (
        <span className={classes}>
          Your free trial expires {trialRemainingDaysFormatted}. Start a
          subscription now &ndash; you only get charged when your free trial
          ends.
        </span>
      );

    case SSXSubscriptionStage.Late:
      return (
        <span className={classes}>
          Your free trial expires {trialRemainingDaysFormatted}. Set up a
          subscription now &ndash; you will not be charged until your free trial
          expires on {subscriptionTrialEndAtFormatted}.
        </span>
      );

    case SSXSubscriptionStage.Expired:
      return (
        <span className={classes}>
          Your free trial expired. Your URL is no longer accessible and changes
          to your profile cannot be published.
        </span>
      );

    case SSXSubscriptionStage.Inactive:
      return (
        <span className={classes}>
          Your subscription is inactive. Your URL is no longer accessible and
          changes to your profile cannot be published.
        </span>
      );

    default:
      return null;
  }
};

const Cta = () => {
  const history = useHistory();
  const uri =
    process.env.REACT_APP_TRIAL_BANNER_CTA || "/account-setting/subscription/select-plan";
  const { sendSegmentEvent } = useAnalytics();
  const ua = useUserAgentContext();
  const subscription = useTypedSelector(selectProfileBuilderSubscription);

  // checks if user accessing the profile is owner of talentProfile
  const talent = useTypedSelector(selectUserData);

  const isOwner = hasOwnership(talent);
  return (
    <a
      onClick={() => {
        const { trialRemainingDays, status } = getSubscriptionOverviewData(subscription);
        sendSegmentEvent(SEGMENT_EVENTS.CLICK_SUBSCRIBE, {
          "Button placement": "Profile editor",
          "Free trial days remaining": trialRemainingDays,
          "Operating System": ua.os.name,
          "Profile Creation Method": "Self Sign Up",
          "Subscription status": status
            ? {
                [OverviewStatus.ACTIVE]: "Is subscribed" as const,
                [OverviewStatus.FREE_TRIAL]: "On trial" as const,
                [OverviewStatus.FREE_TRIAL_EXPIRED]: "Trial expired" as const,
                [OverviewStatus.CANCELLED]: "Cancelled" as const,
              }[status]
            : undefined,
        });
        isOwner ? history.push(uri) :  notification.error({
          message: " Sorry, subscriptions can only be managed by the account holder"
        })
      }}
      className="ssx-subscription-banner__cta"
    >
      Subscribe
    </a>
  );
};

const SubscriptionBanner = (props: SubscriptionDetails) => {
  const { stage } = props;

  if (stage === SSXSubscriptionStage.None) {
    return null;
  }

  const containerClasses = classNames({
    "ssx-subscription-banner__container": true,
    [`stage-${stage.toLowerCase()}`]: true,
  });

  return (
    <div className={containerClasses}>
      <div className="ssx-subscription-banner__info">
        <Icon stage={stage} />
        <Message {...props} />
      </div>
      <Cta />
    </div>
  );
};

export default SubscriptionBanner;
